import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  claim?: string
  items: ItemProps[]
  title?: string
  roomsURL?: string
}

export const RoomsSlider = memo(function RoomsSlider({
  claim,
  items,
  title,
  roomsURL,
}: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, galleryRef] = useKeenSlider({
    loop: items.length > 1 ? true : false,
    drag: false,
    slides: {
      perView: 1.55,
      origin: 'center',
      spacing: 30,
    },
    breakpoints: {
      '(max-width: 1199px)': {
        drag: true,
        slides: {
          perView: 1.55,
          origin: 'center',
          spacing: 20,
        },
      },
      '(max-width: 480px)': {
        drag: true,
        slides: {
          perView: 1.35,
          origin: 'center',
          spacing: 10,
        },
      },
    },
    created(s) {
      setCurrentSlide(s.track.details.rel)
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
  })

  return (
    <Container>
      {claim ? (
        <FadeInUp>
          <Claim>{claim}</Claim>
        </FadeInUp>
      ) : null}
      {title ? (
        <FadeInUp>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
        </FadeInUp>
      ) : null}
      <FadeInUp>
        <Slider ref={sliderRef} className="keen-slider">
          {items.map((item, index) => (
            <Slide className="keen-slider__slide" key={index}>
              <Item
                {...item}
                isCurrentSlide={index === currentSlide}
                roomsURL={roomsURL}
              />
            </Slide>
          ))}
          {items.length > 2 ? (
            <Arrows row space="between">
              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || galleryRef.current?.prev()
                }
              />
              <Arrow
                direction="R"
                onClick={(e) =>
                  e.stopPropagation() || galleryRef.current?.next()
                }
              />
            </Arrows>
          ) : null}
        </Slider>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  padding: 7.5vw 0 3vw;
  padding-top: 0;
  top: -4.5vw;

  @media (max-width: 1439px) {
    padding: 7.5vw 0 3vw;
    padding-top: 0;
  }

  @media (max-width: 1199px) {
    padding: 5rem 0 5rem;
    padding-top: 2.5rem;
    top: 0;
  }
`

const Claim = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.93vw;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 1199px) {
    margin-bottom: -0.625rem;
    font-size: 0.6875rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 5.31vw;
  line-height: 1.058;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 3.4375rem;
  }

  strong {
    color: ${({ theme }) => theme.colors.variants.neutralLight3};
    font-weight: unset;
    font-size: 3.43vw;

    @media (max-width: 1199px) {
      font-size: 1.5625rem;
    }
  }
`

const Slider = styled.div`
  width: 100%;
  margin-top: 6.25vw;

  @media (max-width: 1199px) {
    margin-top: 3.75rem;
  }
`

const Slide = styled.div``

const Arrows = styled(FlexBox)`
  position: absolute;
  top: 50%;
  right: 3.75vw;
  left: 3.75vw;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 3;

  @media (max-width: 767px) {
    top: 40%;
  }

  @media (max-width: 480px) {
    top: 33%;
    right: 1.875rem;
    left: 1.875rem;

    & .arrow {
      width: 2.1875rem;
      height: 2.1875rem;

      svg {
        height: 0.75rem;
      }
    }
  }
`
