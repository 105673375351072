import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Socials } from 'app/components/Common/Socials'
import { CapitalLetter } from 'app/components/Icons'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo } from 'react'

interface ImagesProps {
  image?: ImageProps
}

export interface Props {
  facebookURL?: string
  images: ImagesProps[]
  instagramURL?: string
  languageCode: string
  pinterestURL?: string
  youtubeURL?: string
}

export const InstagramSlider = memo(function InstagramSlider({
  images,
  facebookURL,
  instagramURL,
  languageCode,
  pinterestURL,
  youtubeURL,
}: Props) {
  if (!images) {
    return null
  }
  if (images?.length < 1) {
    return null
  }

  const [sliderRef] = useKeenSlider({
    loop: true,
    drag: true,
    range: {
      min: 0,
    },
    slides: {
      perView: 'auto',
      spacing: 35,
    },
    breakpoints: {
      '(max-width: 1199px)': {
        slides: {
          perView: 2.5,
          spacing: 25,
        },
      },
      '(max-width: 767px)': {
        slides: {
          perView: 1.5,
          spacing: 15,
        },
      },
      '(max-width: 480px)': {
        slides: {
          perView: 1.4,
          spacing: 15,
        },
      },
    },
  })

  return (
    <>
      <Arc src="/arc_bottom_light.svg" alt="" />

      <Container>
        <FadeInUp>
          <Heading>
            <TitleContainer>
              <CapitalLetter />
              <Title>{'#termedistigliano'}</Title>
            </TitleContainer>
            <SocialsContainer>
              <Socials
                facebookURL={facebookURL}
                instagramURL={instagramURL}
                youtubeURL={youtubeURL}
                pinterestURL={pinterestURL}
                languageCode={languageCode}
              />
            </SocialsContainer>
          </Heading>
        </FadeInUp>

        <FadeInUp>
          <Wrapper>
            <Slider ref={sliderRef} className="keen-slider grab-cursor">
              {images.map((item, index) => (
                <Slide className="keen-slider__slide" key={index}>
                  <StyledImage {...item.image} />
                </Slide>
              ))}
            </Slider>
          </Wrapper>
        </FadeInUp>
      </Container>
    </>
  )
})

const Container = styled.div`
  padding: 7.5vw 0;

  @media (max-width: 1199px) {
    padding: 5rem 0;
  }
`

const Heading = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -3.5vw;
  padding: 0 12.5vw;
  text-align: center;

  @media (max-width: 1439px) {
    padding: 0 1.5625rem;
  }

  @media (max-width: 1199px) {
    margin-bottom: 2.1875rem;
  }
`

const TitleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 19.93vw;
    height: 25.25vw;

    path {
      fill: #67809d;
      opacity: 0.7;
    }

    @media (max-width: 1199px) {
      width: 9rem;
      height: 11.4375rem;
    }
  }
`

const Title = styled.h2`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 3.43vw;
  line-height: 1.27;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 1.5625rem;
  }
`

const SocialsContainer = styled.div`
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1199px) {
    top: 70%;

    svg {
      height: 1.25rem;
    }
  }
`

const Wrapper = styled.div`
  margin-left: -8vw;

  @media (max-width: 1199px) {
    margin-left: 0;
  }
`

const Slider = styled.div`
  width: 100%;
  position: relative;

  @media (max-width: 1199px) {
    width: calc(100% - 1.5625rem) !important;
    margin-left: 1.5625rem;
  }
`

const Slide = styled.div`
  min-width: calc(31.25vw - 4.375rem);
  aspect-ratio: 1;
`

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 1.25vw;
  overflow: hidden;

  @media (max-width: 1199px) {
    border-radius: 0.9375rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    opacity: 0.2;
    z-index: 1;
  }
`

const Arc = styled.img`
  position: relative;
  top: -0.3125rem;
  display: block;
  width: 100%;
  height: 13.68vw;
`
