import styled from '@emotion/styled'
import { AlternateIntro } from 'app/components/AlternateIntro'
import { Cards } from 'app/components/Cards'
import { HeroSlider } from 'app/components/HeroSlider'
import { InstagramSlider } from 'app/components/InstagramSlider'
import { Intro } from 'app/components/Intro'
import { RoomsSlider } from 'app/components/RoomsSlider'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { WellnessSlider } from 'app/components/WellnessSlider'
import { ZigZags } from 'app/components/ZigZags'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.headerProps ? (
        <Header
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          pageID={context.id}
          flashNewsProps={
            context.flashNewsProps ? context.flashNewsProps : null
          }
          {...context.headerProps}
        />
      ) : null}
      {context.heroSliderProps ? (
        <HeroSlider
          {...context.heroSliderProps}
          languagePrefix={context.languagePrefix}
        />
      ) : null}
      <div className="wrapper">
        <img src="/arc_hero.svg" alt="" />
        {context.introProps ? (
          <div className="background">
            <div className="background__color">
              <Intro {...context.introProps} />
            </div>
          </div>
        ) : null}
        {context.cardsProps ? (
          <div className="background">
            <div className="background__color">
              <Cards {...context.cardsProps} className="tris" />
            </div>
          </div>
        ) : null}
        {context.wellnessSliderProps ? (
          <div className="background-dark">
            <img
              src="/arc_top_dark.svg"
              alt=""
              style={{ backgroundColor: '#ffffff' }}
            />
            <div className="background-dark__color">
              <WellnessSlider {...context.wellnessSliderProps} />
            </div>
          </div>
        ) : null}
        {context.roomsSliderProps ? (
          <div className="background-light">
            <img
              src="/arc_top_light.svg"
              alt=""
              style={{ backgroundColor: '#293546' }}
            />
            <div className="background-light__color">
              <RoomsSlider {...context.roomsSliderProps} />
            </div>
          </div>
        ) : null}
        {context.introBottomProps ? (
          <div className="background">
            <img
              src="/arc_top.svg"
              alt=""
              style={{ backgroundColor: '#F3EFEA' }}
            />
            <div className="background__color">
              <AlternateIntro {...context.introBottomProps} />
            </div>
          </div>
        ) : null}
        {context.zigZagsProps ? (
          <div className="background-light">
            <img src="/arc_top_light.svg" alt="" />
            <div className="background-light__color">
              <ZigZags {...context.zigZagsProps} className="home" />
            </div>
          </div>
        ) : null}
        {context.instagramProps ? (
          <div className="background-dark">
            <div className="background-dark__color">
              <InstagramSlider {...context.instagramProps} />
            </div>
          </div>
        ) : null}
        {context.footerProps ? (
          <Footer
            languageCode={context.languageCode}
            {...context.footerProps}
          />
        ) : null}
      </div>
    </Container>
  )
})

const Container = styled.main``
