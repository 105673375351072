import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Syncrobox } from 'app/components/Common/Header/Syncrobox'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media } from 'app/theme/media'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'
import { useInView } from 'react-intersection-observer'

interface ItemProps {
  cta?: ButtonProps
  image?: ImageProps
  title?: string
}

export interface Props {
  items: ItemProps[]
  languageCode: string
  languagePrefix?: string
}

export const HeroSlider = memo(function HeroSlider({
  items,
  languageCode,
  languagePrefix,
}: Props) {
  if (!items) {
    return null
  }
  if (items?.length < 1) {
    return null
  }

  const [animObjectRef, isAnimObjectVisible] = useInView({ triggerOnce: true })
  const [currentSlide, setCurrentSlide] = useState(0)
  const [opacities, setOpacities] = useState<number[]>([])

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: items.length > 1 ? true : false,
    slides: items.length,
    drag: false,
    detailsChanged(s) {
      const new_opacities = s.track.details.slides.map((slide) => slide.portion)
      setOpacities(new_opacities)
    },
  })

  const [claimsSliderRef, claimsRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1500,
    },
    drag: false,
    loop: false,
    slides: {
      origin: 'center',
      perView: 2.8,
    },
    breakpoints: {
      '(max-width: 767px)': {
        drag: true,
        slides: {
          origin: 'center',
          perView: 2,
        },
        slideChanged(s) {
          setCurrentSlide(s.track.details.rel)
          instanceRef.current?.moveToIdx(s.track.details.rel)
        },
      },
    },
  })

  return (
    <Container>
      <Wrapper>
        <Slider ref={sliderRef} className="keen-slider fader">
          {items.map((item, index) => (
            <Slide
              className={
                currentSlide === index ? 'fader__slide current' : 'fader__slide'
              }
              key={index}
              style={{ opacity: opacities[index] }}
            >
              <StyledImage {...item.image} />
            </Slide>
          ))}
        </Slider>

        <Media greaterThanOrEqual="desktopSmall">
          <Inner
            row={false}
            dial={5}
            ref={animObjectRef}
            className={isAnimObjectVisible ? 'visible' : undefined}
          >
            <Logo src="/logo_hero_slider.svg" alt="Logo Terme di Stigliano" />
            <Claims>
              {items.map((item, index) => (
                <Claim
                  className={currentSlide === index ? 'current' : undefined}
                  key={index}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(index)
                    setCurrentSlide(index)
                  }}
                >
                  <Title>{item.title}</Title>
                  {item.cta ? (
                    <StyledButton {...item.cta} variant="light" />
                  ) : null}
                </Claim>
              ))}
            </Claims>
          </Inner>
          <Syncrobox
            languageCode={languageCode}
            languagePrefix={languagePrefix || 'it'}
          />
        </Media>

        <Media lessThan="desktopSmall">
          <Inner row={false} dial={5}>
            <Logo src="/logo_hero_slider.svg" alt="Logo Terme di Stigliano" />
            <Claims ref={claimsSliderRef}>
              {items.map((item, index) => (
                <Claim
                  className={`keen-slider__slide${
                    currentSlide === index ? ' current' : ''
                  }`}
                  key={index}
                >
                  <Title>{item.title}</Title>
                  {item.cta ? (
                    <StyledButton {...item.cta} variant="light" />
                  ) : null}
                </Claim>
              ))}
            </Claims>
          </Inner>
        </Media>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.colors.variants.primaryDark};
`

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Slider = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`

const Slide = styled.div``

const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(20, 21, 23, 0.15) 0%, #141517 100%);
    opacity: 0.6;
  }
`

const Inner = styled(FlexBox)`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  transition: 0.6s 0.2s ease-in-out;
  overflow-x: hidden;

  @media (min-width: 1200px) {
    opacity: 0;
  }

  &.visible {
    opacity: 1;
    transform: translateY(-50%);
  }
`

const Logo = styled.img`
  width: auto;
  height: 14.81vw;

  @media (max-width: 1439px) {
    min-height: 14.375rem;
  }

  @media (max-width: 1199px) {
    position: absolute;
    top: 30%;
    min-height: auto;
  }

  @media (max-width: 767px) {
    height: 6.5625rem;
  }
`

const Claims = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 60vw;

  @media (max-width: 1439px) {
    max-width: 56.25rem;
  }

  @media (max-width: 1199px) {
    max-width: 100%;
    height: 50%;
    position: absolute;
    bottom: 3.75rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 3.43vw;
  line-height: 1;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 2.1875rem;
  }
`

const StyledButton = styled(Button)`
  margin-top: 1.87vw;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`

const Claim = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  margin: 5.87vw 0;
  padding: 0 1.5625rem;
  opacity: 0.5;
  transition: opacity 0.25s ease-in-out;
  cursor: pointer;

  @media (max-width: 1439px) {
    margin: 5.625rem 0;
  }

  @media (max-width: 1199px) {
    width: 100%;
    margin: 0;
  }

  &.current {
    opacity: 1;

    a {
      opacity: 1;
      pointer-events: all;
    }

    ${Title} {
      @media (max-width: 1439px) {
        font-size: 3.4375rem;
      }

      @media (max-width: 767px) {
        font-size: 2.8125rem;
      }
    }
  }
`
