import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  className?: string
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  label?: string
  onClick?: any
  style?: any
  title?: string
}

export const Item = memo(function Item({
  className,
  cta,
  description,
  image,
  label,
  onClick,
  style,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container
      row={false}
      dial={5}
      className={className}
      style={style}
      onClick={onClick}
    >
      {label ? <Label>{label}</Label> : null}
      {title ? <Title>{title}</Title> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      {cta ? <StyledButton {...cta} variant="light" /> : null}
    </Container>
  )
})

const Label = styled.div`
  margin-bottom: 0.93vw;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.93vw;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;

  @media (max-width: 1199px) {
    margin-bottom: 0.9375rem;
    font-size: 0.6875rem;
  }
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 3.43vw;
  line-height: 1.18;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.25s ease-in-out;

  @media (max-width: 1199px) {
    font-size: 2.8125rem;
  }
`

const Description = styled.div`
  margin-top: 1.87vw;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 1.06vw;
  line-height: 1.64;
  text-align: center;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;

  @media (max-width: 1199px) {
    margin-top: 1.25rem;
    font-size: 0.8125rem;
  }
`

const StyledButton = styled(Button)`
  margin-top: 3.12vw;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
  }
`

const Container = styled(FlexBox)`
  padding: 0 1.5625rem;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  z-index: 4;

  @media (min-width: 1200px) {
    cursor: pointer;
  }

  @media (max-width: 1199px) {
    height: 100%;
    justify-content: flex-end;
    padding-bottom: 2.5rem;
  }

  &.active
    ${Label},
    &.active
    ${Title},
    &.active
    ${Description},
    &.active
    ${StyledButton} {
    opacity: 1;
  }
`
