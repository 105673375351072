import styled from '@emotion/styled'
import { CursorButton } from 'app/components/Common/CursorButton'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Media } from 'app/theme/media'
import { Link } from 'gatsby'
import React, { memo, useRef, useState } from 'react'

export interface Props {
  claim?: string
  image?: ImageProps
  isCurrentSlide?: boolean
  languageCode: string
  title?: string
  roomsURL?: string
}

export const Item = memo(function Item({
  claim,
  image,
  isCurrentSlide,
  languageCode,
  title,
  roomsURL,
}: Props) {
  const containerRef = useRef<any>()
  const section = title
    ? title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()
    : undefined

  const [buttonVisibility, setButtonVisibility] = useState(false)

  return (
    <Container
      className={isCurrentSlide ? 'current' : undefined}
      onMouseEnter={() => setButtonVisibility(true)}
      onMouseLeave={() => setButtonVisibility(false)}
      ref={containerRef}
      to={`${roomsURL}#room-${section}`}
    >
      {image ? <StyledImage {...image} /> : null}

      <Inner>
        {title ? <Title>{title}</Title> : null}
        {claim ? <Claim>{claim}</Claim> : null}
      </Inner>

      {isCurrentSlide ? (
        <Media greaterThanOrEqual="ipadVertical">
          <CursorButton
            container={containerRef}
            hasOffset={true}
            languageCode={languageCode}
            visibility={buttonVisibility}
          />
        </Media>
      ) : null}
    </Container>
  )
})

const Container = styled(Link)`
  display: block;
  position: relative;
  border-radius: 1.25vw;
  overflow: hidden;
  pointer-events: none;

  &.current {
    cursor: none;
    pointer-events: all;

    @media (max-width: 767px) {
      cursor: pointer;
    }
  }

  @media (max-width: 767px) {
    border-radius: 0.625rem;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 1.7;
  min-height: 11.875rem;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8 !important;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background: linear-gradient(rgba(20, 21, 23, 0) 0%, #141517 100%);
    opacity: 0.65;
    z-index: 1;
  }

  @media (max-width: 767px) {
    aspect-ratio: unset;
    min-height: 16.25rem;
  }
`

const Inner = styled.div`
  position: absolute;
  bottom: 2.18vw;
  left: 50%;
  transform: translateX(-50%);
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  text-align: center;
  z-index: 3;

  @media (max-width: 1199px) {
    bottom: 2.1875rem;
  }

  @media (max-width: 767px) {
    position: static;
    transform: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.25rem;
    color: ${({ theme }) => theme.colors.variants.primaryDark};
  }

  @media (max-width: 480px) {
    padding: 0 1.5625rem;
  }
`

const Title = styled.div`
  margin-bottom: 0.7vw;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.81vw;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 0.6875rem;
  }

  @media (max-width: 767px) {
    margin-bottom: 0.75rem;
  }
`

const Claim = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 2.81vw;
  line-height: 1;

  @media (max-width: 1199px) {
    font-size: 1.5625rem;
    line-height: 1.4;
  }
`
