import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  container?: any
  hasOffset?: boolean
  isModal?: boolean
  languageCode: string
  onClick?: any
  thumbs?: any
  visibility?: boolean
}

export const CursorButton = memo(function CursorButton({
  container,
  hasOffset,
  isModal,
  languageCode,
  visibility,
}: Props) {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  const getOffset = (element: any) => {
    const rect = element.getBoundingClientRect()
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    }
  }

  const onMouseMove = (e: any) => {
    const { pageX: x, pageY: y, clientY: yy } = e

    if (container.current) {
      if (hasOffset) {
        setMousePosition({
          x: x - getOffset(container.current).left - 60,
          y: isModal
            ? yy
            : y -
              container.current?.offsetTop -
              getOffset(container.current).top -
              60,
        })
      } else {
        setMousePosition({
          x: x - 60,
          y: isModal ? yy : y - container.current?.offsetTop - 60,
        })
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)

    return () => {
      window.removeEventListener('mousemove', onMouseMove)
    }
  }, [container])

  return (
    <Container
      className={visibility ? 'visible' : undefined}
      dial={5}
      style={{
        left: `${mousePosition.x}px`,
        top: `${mousePosition.y}px`,
      }}
    >
      {useVocabularyData('enter', languageCode)}
    </Container>
  )
})

const Container = styled(FlexBox)`
  position: absolute;
  width: 7.5vw;
  height: 7.5vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.75vw;
  letter-spacing: 0.05em;
  opacity: 0;
  text-align: center;
  pointer-events: none;
  text-transform: uppercase;
  transition: opacity 0.15s ease-in-out;
  z-index: 3;
  &.visible {
    opacity: 1;
  }

  @media (max-width: 1199px) {
    width: 7.5rem;
    height: 7.5rem;
    font-size: 0.75rem;
  }
`
